import styled, { css, keyframes } from 'styled-components'
import { theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

const Container = styled.div<{ isOpen: boolean }>`
	position: relative;
	width: 100%;
	margin-right: 40px;
	background: ${theme.colors.blueIce};
	border-radius: 5px;
	z-index: ${({ isOpen }) => (isOpen ? `2` : '1')};
	font-family: ${theme.fonts.body};
	transition: background 250ms ease-in-out;

	&:focus,
	&:focus-within,
	&:hover {
		background: ${theme.colors.greyAccentLight};
	}

	@media ${theme.breakpoints.xs} {
		margin-right: 0;
	}

	@media ${theme.breakpoints.smMax} {
		&:nth-child(2n) {
			& .options-list {
				left: auto;
				right: 0;
			}
		}
	}

	@media ${theme.breakpoints.md} {
		margin-bottom: 1rem;
		width: auto;
		min-width: 168px;
	}
`

const Button = styled.button`
	min-width: 100%;
	display: flex;
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	border: 0;
	padding: 16px 15px 15px 24px;
	line-height: ${theme.typography.lineHeightTextSmall};
	letter-spacing: 0.2px;
	font-size: ${theme.typography.fontSizeTextSmall}px;
	font-weight: ${theme.typography.fontWeightMedium};
	outline: none;
	color: ${theme.colors.marineBlack};
	position: relative;
	justify-content: space-between;

	&::after {
		content: attr(data-text);
		height: 0;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		pointer-events: none;
		font-weight: ${theme.typography.fontWeightBold};
		position: absolute;

		@media speech {
			display: none;
		}
	}

	@media ${theme.breakpoints.xs} {
		margin: 0;
	}
`

const IconContainer = styled.div<{ isRotated: boolean }>`
	display: flex;
	align-items: center;
	padding: 8px;
	transition: transform ${theme.timing.fast} ease-in-out;
	margin-left: 16px;

	${({ isRotated }) =>
		isRotated &&
		css`
			transform: rotate(180deg);
		`}
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -20px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`

const NoResultsFound = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: ${theme.colors.marineBlack};
	letter-spacing: 0.2px;
`

const Options = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'inline-flex' : 'none')};
	animation: ${fadeInDown} ${theme.timing.fast};
	flex-direction: column;
	position: absolute;
	min-height: 176px;
	max-height: 328px;
	padding: 24px;
	overflow-y: auto;
	z-index: 1;
	box-shadow: 10px 20px 40px 0
		${hexToRgb(theme.colors.marineBlack, { alpha: 0.12, format: 'css' })};
	background-color: ${theme.colors.white};
	box-sizing: border-box;

	@media ${theme.breakpoints.xs} {
		min-width: 100%;
		max-width: 350px;
		width: 343px;
	}

	@media ${theme.breakpoints.md} {
		min-width: 328px;
		max-width: 328px;
	}
`

const Option = styled.div`
	label {
		display: flex;
		align-items: center;
		color: ${theme.colors.marineBlack};
		transition: color ${theme.timing.fast} ease-in-out;
		cursor: pointer;
		margin: 0;
	}

	span {
		span,
		svg {
			width: 16px;
			height: 16px;
		}

		span {
			margin-right: 16px;
		}
	}

	& + & {
		label {
			margin-top: 24px;
		}
	}
`

const SearchInput = styled.input`
	font-weight: ${theme.typography.fontWeightMedium};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	color: ${theme.colors.marineBlack};
	line-height: 1.14;
	width: 100%;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-bottom: 1px solid ${theme.colors.greyAccentLight};
	margin-bottom: 24px;
	padding-right: 25px;
	padding-bottom: 24px;

	&::placeholder {
		color: ${theme.colors.marineBlack};
	}

	&:focus {
		outline: 0;

		&::placeholder {
			opacity: 0;
		}
	}
`

const SearchIconWrapper = styled.span`
	position: absolute;
	top: 0;
	right: 0;
`

const FilterSearchWrapper = styled.div`
	position: relative;
	display: block;
`

const FilterSearchClearButton = styled.button`
	background: transparent;
	border: none;

	&:hover {
		cursor: pointer;
	}
`

export {
	Container,
	Option,
	Options,
	IconContainer,
	Button,
	NoResultsFound,
	SearchInput,
	SearchIconWrapper,
	FilterSearchWrapper,
	FilterSearchClearButton
}
