import styled from 'styled-components'
import { theme } from '@damen/ui'

export const TableElement = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
`

export const TableHeadElement = styled.thead`
	position: relative;
	display: none;

	@media ${theme.breakpoints.md} {
		display: table-header-group;
	}
`

export const TableHeadRow = styled.tr`
	position: relative;
	border-bottom: 1px solid ${theme.colors.greyAccentLight};
`

export const TableHeading = styled.th`
	position: relative;
	padding: 8px 8px 8px 0;
	width: 20%;
`

export const TableRow = styled.tr`
	position: relative;
	border-bottom: 1px solid ${theme.colors.greyAccentLight};
`

export const TableBodyElement = styled.tbody`
	position: relative;
`

export const TableColumnnSubline = styled.span`
	display: block;
`

export const TableColumnElement = styled.td`
	position: relative;
	display: table-cell;
	height: 64px;
	padding: 0 8px 0 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
	}
`

export const TableContentWrapper = styled.div`
	width: 100%;
`
