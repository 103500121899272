import { theme } from '@damen/ui'
import styled from 'styled-components'
import { FilterElementProps } from './types'

export const FiltersGroupWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
`

export const Filter = styled.div<FilterElementProps>`
	margin-right: 10px;
	width: ${({ amount }) => `${100 / amount}%`};

	@media ${theme.breakpoints.sm} {
		margin-right: 40px;
	}
`
