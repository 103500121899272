import React, { useMemo } from 'react'

import Select from '@components/Select'
import { FiltersGroupWrapper } from '@components/UI/Filter/Group/styles'
// import SingleSelect from '@damen/ui/lib/cjs/components/Filters/Select'
import SingleSelect from '@damen/ui/lib/cjs/components/Form/Select'
import { theme } from '@damen/ui'

import styled from 'styled-components'
import { Container } from './UI/Filter/Search/styles'

interface Props<T extends Record<string, any>> {
	facets: Array<{
		key: keyof T & string
		label: string
		search?: boolean
		options: Array<{
			id: string
			name: string
		}>
		singleSelect?: boolean
	}>
	activeFilters: T
	onChangeFilters: (filters: T) => void
	filterSearchPlaceholder?: string
	noSearchResultsLabel?: string
}

type Facet = Props<any>['facets'][0] &
	Pick<
		Props<any>,
		| 'activeFilters'
		| 'onChangeFilters'
		| 'noSearchResultsLabel'
		| 'filterSearchPlaceholder'
	> & { field: string }

// Width is two times a normal select (original was min-width: 168px;)
const StyledSingleSelct = styled.div`
	min-width: 336px;

	label {
		background: ${theme.colors.silverGrey200} !important;
		border: 0 !important;
		font-weight: 500;
	}
`

const CurrentSingleSelect = ({
	field,
	label,
	options,
	activeFilters,
	onChangeFilters
}: Facet) => {
	const formattedOptions = useMemo(
		() =>
			options?.map(({ id, name }) => ({
				value: id,
				label: name
			})),
		[options]
	)
	const isDisabled = options.length === 0

	return (
		<Container isOpen={false}>
			<StyledSingleSelct>
				<SingleSelect
					key={field}
					readOnly={isDisabled}
					placeholder={label}
					options={formattedOptions}
					initialValue={activeFilters[field]}
					onSelect={(selectedValue) => {
						onChangeFilters({
							...activeFilters,
							[field]: selectedValue
						})
					}}
				/>
			</StyledSingleSelct>
		</Container>
	)
}

const CurrentMultiSelect = ({
	field,
	label,
	search,
	noSearchResultsLabel,
	filterSearchPlaceholder,
	options,
	activeFilters,
	onChangeFilters
}: Facet) => {
	const formattedOptions = options?.map(({ id, name }) => ({
		value: id,
		label: name,
		selected: activeFilters[field]?.includes(id)
	}))

	const isDisabled = options.length === 0

	return (
		<Select
			disabled={isDisabled}
			key={field}
			label={
				<>
					{label}
					{activeFilters[field]?.length > 0 &&
						` (${activeFilters[field].length})`}
				</>
			}
			search={search && options.length > 10}
			searchPlaceholder={filterSearchPlaceholder}
			noResultsFound={noSearchResultsLabel}
			options={formattedOptions}
			onChange={(selectedValues) => {
				onChangeFilters({
					...activeFilters,
					[field]: selectedValues
				})
			}}
		/>
	)
}

const SelectFacets = <T extends Record<string, any>>({
	facets,
	activeFilters,
	onChangeFilters,
	noSearchResultsLabel,
	filterSearchPlaceholder
}: Props<T>) => (
	<FiltersGroupWrapper>
		{facets.map(({ key, label, search, options, singleSelect }) => {
			if (singleSelect) {
				return (
					<CurrentSingleSelect
						key={key}
						field={key}
						activeFilters={activeFilters}
						onChangeFilters={onChangeFilters}
						label={label}
						search={search}
						options={options}
						singleSelect
					/>
				)
			}
			return (
				<CurrentMultiSelect
					key={key}
					field={key}
					activeFilters={activeFilters}
					onChangeFilters={onChangeFilters}
					label={label}
					search={search}
					options={options}
					singleSelect={false}
					noSearchResultsLabel={noSearchResultsLabel}
					filterSearchPlaceholder={filterSearchPlaceholder}
				/>
			)
		})}
	</FiltersGroupWrapper>
)

export default SelectFacets
